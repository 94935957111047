/* eslint-disable no-console */
<template>
  <div class="tweet-this-container">
  <slot ref="bodySlot"/>
  <div
    v-if="showMenu"
    :style="{
      left: `${x}px`,
      top: `${y}px`
    }"
    class="tweet-this-container__pop-up"
    @mousedown.prevent="">
    <a
      :href="tweetableLink"
      class="tweet-this-container__button"
      @mousedown.prevent="tweetThis">
      <img
        src="/svgs/news/news-social-twitter--white.svg"
        alt="Twitter Share"
        @mousedown.prevent="">
      <span
        class="tweet-this-container__copy"
        @mousedown.prevent="">
        Tweet This
      </span>
    </a>
  </div>
  </div>
</template>
<style scoped>
.tweet-this-container__pop-up {
  height: 41px;
  padding: 11px 23px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 165px;
}
.tweet-this-container__button {
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.tweet-this-container__copy{
  font-family: "proxima-nova",
   "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
}
.tweet-this-container__pop-up:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -11px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid rgba(0, 0, 0, 0.8);
}
</style>
<script>
export default {
  props: {
    tweetUrl: {
      type: String,
      required: false,
      default: window.location.href,
    },
    tweetImage: {
      type: String,
      required: false,
      default: '' },
  },
  data() {
    return {
      x: 0,
      y: 0,
      isSelectable: false,
      showMenu: false,
      selectedText: '',
      tweetableLink: '',
    }
  },
  mounted() {
    window.addEventListener('mouseup', this.onMouseup)
    window.addEventListener('mousedown', this.onMousedown)
    this.addHighlightableClass(this.$slots.default)
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseup)
    window.addEventListener('mousedown', this.onMousedown)
  },
  methods: {
    addHighlightableClass(parentElement) {
      parentElement.forEach((child) => {
        // Check if child has an HTML tag
        if (child.tag) {
          // Add class to children
          child.elm.classList.add('highlightable')
          // Check if child has subchildren
          if (child.children) {
            // Run this function on list of subchildren
            this.addHighlightableClass(child.children)
          }
        }
      })
    },
    textIsSelectable(element) {
      this.isSelectable = false
      this.highlightableEls.forEach((el) => {
        if (el.isSameNode(element)) {
          this.isSelectable = true
        }
      })
    },
    encodeStringForTwitter(str) {
       return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16)}`)
    },
    tweetThis(event) {
      if (this.selectedText) {
        const tweetString = `${this.encodeStringForTwitter(this.selectedText)}%20${this.tweetUrl ? `${this.tweetUrl}%20` : ''}${this.tweetImage ? `${this.tweetImage}` : ''}`
        this.tweetableLink = `https://twitter.com/intent/tweet?text=${tweetString}`

        event.preventDefault()

        window.open(
          this.tweetableLink,
          'Twitter Window',
          'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0,scrollbars=0',
        )
      }
    },
    clearTheSelection() {
       if (window.getSelection) {
        if (window.getSelection().empty) { // Chrome
          window.getSelection().empty()
        } else if (window.getSelection().removeAllRanges) { // Firefox
          window.getSelection().removeAllRanges()
        }
       } else if (document.selection) { // IE?
        document.selection.empty()
       }
    },
    onMouseup() {
      this.showMenu = false

      const selection = window.getSelection()
      const selectionRange = selection.getRangeAt(0)
      // startNode is the element that the selection starts in
      const startNode = selectionRange.startContainer.parentNode
      // endNode is the element that the selection ends in
      const endNode = selectionRange.endContainer.parentNode
      // if the selected text is not part
      // of the highlightableEl (i.e. has a 'highlightable' class)
      // OR
      // if startNode !== endNode (i.e. the user selected multiple paragraphs)
      // Then
      // Don't show the menu (this selection is invalid)
      if (!startNode.classList.contains('highlightable') || !startNode.isSameNode(endNode)) {
        this.showMenu = false
        this.selectedText = ''

        return
      }
      // Get the x, y, and width of the selection
      const { x, y, width } = selectionRange.getBoundingClientRect()
      // If width === 0 (i.e. no selection)    // Then, hide the menu
      if (!width || width <= 1) {
        this.showMenu = false
        this.selectedText = ''
        return
      }
      // Finally, if the selection is valid,
      // set the position of the menu element,
      // set selectedText to content of the selection
      // then, show the menu
      this.x = x + (width / 2)
      // eslint-disable-next-line no-mixed-operators
      this.y = y + window.scrollY - 10

      if (!selection || !selection.toString()) {
        this.showMenu = false
        this.selectedText = ''
        return
      }
      this.selectedText = selection.toString()
      this.showMenu = true
    },
    onMousedown() {
      this.clearTheSelection()
    },
  },
}
</script>
