<template>
    <div class="rfiModal" id="rfiModal" v-if="modal">
        <div class="overlay" style="display:block;" @click="removeModal"></div>
        <div class="form_rfi">
            <button id="rfiCloseBtn" @click="removeModal">Close</button>
            <h1>Request More Information</h1>
            <div class="form_question form_select form_layout_stacked" style="clear:both;">
                <label class="form_label" for="level_of_study">Level of Study</label>
                <div class="form_responses">
                    <select v-model="level_of_study" @change="formHandler" ref="level_of_study" id="level_of_study" size="1" autocomplete="off">
                        <option disabled selected>Choose one...</option>
                        <!--<option value="associate">Associate</option>-->
                        <option value="undergraduate">Undergraduate</option>
                        <option value="graduate">Graduate</option>
                        <!--<option value="certificate">Certificate</option>-->
                    </select>
                </div>
            </div>
            <div v-if="campus_select" class="form_question form_select form_layout_stacked">
                <label class="form_label" for="campus">Campus</label>
                <div class="form_responses">
                    <select v-model="campus" @change="formHandler" id="campus" size="1" autocomplete="off">
                        <option></option>
                        <option value="oxford">Oxford (Main Campus)</option>
                        <option value="hamilton">Hamilton</option>
                        <option value="middletown">Middletown</option>
                        <option value="online">Online</option>
                    </select>
                </div>
            </div>
            <div v-show="form_ox" id="form_ox">Loading...</div>
            <div v-show="form_gr" id="form_gr">Loading...</div>
            <div v-show="form_reg" id="form_reg">Loading...</div>
        </div>
    </div>
</template>

<style>
.rfiModal {
    z-index:100;
}
.rfiModal h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    float: left;
    display: inline-block;
    width: 80%;
}
.rfiModal .overlay {
    background-color:rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index:110;
}
.rfiModal .form_rfi {
    position: fixed;
    z-index:120;
}
.rfiModal .modal {
    position:fixed;
    z-index:120;
}

.rfiModal .form_rfi {
    background-color:#edece2;
    padding: 20px;
}
.rfiModal div.action.form_action > button, .form_button_submit {
    background-color: #c41230;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius:0px!important;
    text-transform:uppercase;
}
.rfiModal div.action.form_action > button:focus, .form_button_submit:focus {
    background-color: #ad102a;
}
.form_rfi {
    margin:40px auto;
    font-family: sans-serif;
    max-height:calc(100vh - 80px);
    overflow-y:auto;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    left: calc(50% - 150px); 
}

.form_rfi *:focus {
    outline-color: #c41230;
    outline-style: inset;
}

.form_rfi #form_description {
    display:none;
}

@media screen and (min-width: 480px) {
    .form_rfi {
        width: 480px;
        left: calc(50% - 240px);
    }
}

@media screen and (min-width: 768px) {
    .form_rfi {
        width: 760px;
        left: calc(50% - 380px);
    }
}

.form_rfi .form_question {
    display: block;
}

.form_rfi div.form_header .form_label {
    background:#D6D2C1!important;
}

.form_rfi .form_question label, .form_rfi div.form_page label {
    font-size: 1rem;
    margin-bottom: 5px;
}
.form_rfi .form_question input, .form_rfi .form_question select {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px!important;
    width: 100%!important;
}

.form_rfi .form_address input, .form_rfi .form_address select, .form_rfi .form_address textarea {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px!important;
    width: 100%!important;
    margin-bottom: 1em;
}

.form_rfi .form_question input[type='checkbox'], .form_rfi .form_question input[type='radio'] {
    font-size: 1rem;
    width: 13px!important;
}

.form_rfi .form_question.form_birthdate select {
    width:30%!important;
}

.form_rfi legend {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.form_rfi .form_birthdate legend, .form_rfi .form_checkbox legend, .form_rfi .form_radio legend {
    font-size: 1rem;
    margin-bottom: 5px;
}

#rfiCloseBtn {
    float: right;
    margin-bottom:10px;
    padding:5px;
}

#rfiCloseBtn:focus {
    color: black;
    background-color:transparent;
}
</style>
<script>

import eventBus from '../../js/util/eventBus';

export default {
    data() {
        return {
            modal: false,
            template: "",
            rfiComponentData: {
                output: null,
                formData: []
            },
            level_of_study: "",
            campus: "",
            campus_select: false,
            form_ox: false,
            form_gr: false,
            form_reg: false,
        }
    },
    mounted() {
        const utility_nav = document.querySelectorAll('.top-menu-wrapper .header__utility_menu ul');
        if (typeof (utility_nav) != undefined && typeof (utility_nav) != null && typeof (utility_nav) != 'undefined') {
            let li = document.createElement('li');
            li.innerHTML = "<a href='#' id='rfiLink'>Request Info</a>";
            utility_nav[0].insertBefore(li, utility_nav[0].children[2]);
        }

        const mobile_utility_nav = document.querySelectorAll('.big-menu-wrapper .other-menus-container .header__utility_menu ul');
        if (typeof (mobile_utility_nav) != undefined && typeof (mobile_utility_nav) != null && typeof (mobile_utility_nav) != 'undefined') {
            let li_mobile = document.createElement('li');
            li_mobile.innerHTML = "<a href='#' id='mobileRfiLink'>Request Info</a>";
            mobile_utility_nav[0].insertBefore(li_mobile, mobile_utility_nav[0].children[2]);
        }

        var del = document.getElementById("rfiLink");
        del.addEventListener('click', (e) => {
            e.preventDefault();
            this.showModal();

            let Script1 = document.createElement("script");
                Script1.setAttribute("src", "https://graduateschool.miamioh.edu/register/?id=8ab6f590-1807-40f2-b707-d4b8e2fa4d8d&output=embed&div=form_gr");
            let Script2 = document.createElement("script");
                Script2.setAttribute("src", "https://admission.miamioh.edu/register/?id=dcb98734-5be9-475c-a293-281d6c92641e&output=embed&div=form_ox");

            let Script3 = document.createElement("script");
                    Script3.setAttribute("src", "https://applyregionals.miamioh.edu/register/?id=ddea20b8-6782-4a00-b988-881760b4aba7&output=embed&div=form_reg");

            document.head.appendChild(Script1);
            document.head.appendChild(Script2);
            document.head.appendChild(Script3);
        });

        var del = document.getElementById("mobileRfiLink");
        del.addEventListener('click', (e) => {
            e.preventDefault();
            this.showModal();
            eventBus.$emit('toggle:menu', false);

            let Script1 = document.createElement("script");
                Script1.setAttribute("src", "https://graduateschool.miamioh.edu/register/?id=8ab6f590-1807-40f2-b707-d4b8e2fa4d8d&output=embed&div=form_gr");
            let Script2 = document.createElement("script");
                Script2.setAttribute("src", "https://admission.miamioh.edu/register/?id=dcb98734-5be9-475c-a293-281d6c92641e&output=embed&div=form_ox");

            let Script3 = document.createElement("script");
                    Script3.setAttribute("src", "https://applyregionals.miamioh.edu/register/?id=ddea20b8-6782-4a00-b988-881760b4aba7&output=embed&div=form_reg");

            document.head.appendChild(Script1);
            document.head.appendChild(Script2);
            document.head.appendChild(Script3);
        });
    },
    created() {
        const self = this;
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                self.modal = false;
            }
        });
    },
    methods: {
        showModal()
        {
            this.modal = true;
            setTimeout(() => {  
                this.$refs.level_of_study.focus();
                this.trapFocus(document.getElementById("rfiModal"));
            }, 100);
        },
        removeModal() {
            this.modal = false;
        },
        formHandler() {
            if(this.level_of_study == "undergraduate" || this.level_of_study == "associate")
            {
                this.campus_select = true;
                
                this.form_ox = false;
                this.form_gr = false;

                if(this.campus == "online")
                {
                    var reg_online_input = document.getElementById('form_888fec88-069c-4ad6-b6e5-9fb6d112d9a1');
                    reg_online_input.selectedIndex = 3;
                    reg_online_input.parentElement.parentElement.style.display = 'none';
                }
                else if(this.campus == "hamilton")
                {
                    var reg_online_input = document.getElementById('form_888fec88-069c-4ad6-b6e5-9fb6d112d9a1');
                    reg_online_input.selectedIndex = 1;
                    reg_online_input.parentElement.parentElement.style.display = 'none';
                }
                else if(this.campus == "middletown")
                {
                    var reg_online_input = document.getElementById('form_888fec88-069c-4ad6-b6e5-9fb6d112d9a1');
                    reg_online_input.selectedIndex = 2;
                    reg_online_input.parentElement.parentElement.style.display = 'none';
                }
            }
            if((this.level_of_study+this.campus == "undergraduateoxford") || (this.level_of_study+this.campus == "undergraduateunsure"))
            {
                this.form_ox = true;
                this.form_gr = false;
                this.form_reg = false;
            }
            else if((this.level_of_study+this.campus == "undergraduatehamilton") || (this.level_of_study+this.campus == "undergraduatemiddletown") || (this.level_of_study+this.campus == "undergraduateonline") || (this.level_of_study == "associate"))
            {
                this.form_ox = false;
                this.form_gr = false;
                this.form_reg = true;
            }
            else if((this.level_of_study == "graduate") || (this.level_of_study == "certificate"))
            {
                this.form_ox = false;
                this.form_gr = true;
                this.form_reg = false;
                this.campus_select = false;
            }
        },
        trapFocus(element) {

            var focusableElements = element.querySelectorAll('button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            var firstFocusableElement = focusableElements[0];  
            var lastFocusableElement = focusableElements[focusableElements.length - 1];
            var KEYCODE_TAB = 9;

            var ox_form = document.getElementById('form_ox');
            var gr_form = document.getElementById('form_gr');
            var reg_form = document.getElementById('form_reg');
            var level_of_study_input = document.getElementById('level_of_study');

            element.addEventListener('keydown', function(e) {
                let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) {

                    var lastFocusableElement = focusableElements[focusableElements.length - 1];

                    if(!document.body.contains(document.getElementById('campus')) && gr_form.style.display == "none")
                    {
                        lastFocusableElement = level_of_study_input;
                    }

                    if(document.body.contains(document.getElementById('campus')) && ox_form.style.display == "none" && reg_form.style.display == "none")
                    {
                        lastFocusableElement = document.getElementById('campus');
                    }

                    if(document.activeElement.id == 'rfiCloseBtn' && gr_form.style.display != "none")
                    {
                        lastFocusableElement = document.querySelector("#form_gr button.default");
                    }

                    if(document.activeElement.id == 'rfiCloseBtn' && reg_form.style.display != "none")
                    {
                        lastFocusableElement = document.querySelector("#form_reg button.default");
                    }

                    if(document.activeElement.id == 'rfiCloseBtn' && ox_form.style.display != "none")
                    {
                        lastFocusableElement = document.querySelector("#form_ox button.default");
                    }

                    if(document.activeElement.id == 'rfiCloseBtn')
                    {
                        lastFocusableElement.focus();
                        e.preventDefault();
                    }

                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus();
                        e.preventDefault();
                    }
                } else {

                    var firstFocusableElement = focusableElements[0];

                    if(document.activeElement === lastFocusableElement)
                    {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                    if(document.activeElement.classList.contains('form_button_submit'))
                    {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                    if(document.activeElement.id == 'level_of_study' && !document.body.contains(document.getElementById('campus')) && document.getElementById('form_gr').style.display == "none")
                    {
                        //console.log(!document.body.contains(document.getElementById('campus')));
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                    if(document.activeElement.id == 'campus' && document.getElementById('form_ox').style.display == "none" && document.getElementById('form_reg').style.display == "none")
                    {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                    /*
                    if(document.activeElement.id == 'campus' && (document.getElementById('form_ox').style.display == "none" && document.getElementById('form_gr').style.display == "none" && document.getElementById('form_reg').style.display == "none"))
                    {
                        console.log(document.activeElement.id);
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                    */

                }
            });
        }
    }
}
</script>