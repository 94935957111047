<template>
  <div>
    <div class="search-result__input-inner">
      <div class="gcse-searchbox"/>
    </div>
    <div class="search-result__input-inner">
      <div class="gsc-control-cse search-result__site-toggle">
        <div class="search-result__site-toggle-label">Show Results From:</div>
        <button
          id="search-result--site-all"
          :class="{ 'search-result__site-toggle-button--selected': !specific }"
          class="search-result__site-toggle-button"
          @click="setSpecific(false)">All Of Miami</button>
        <button
          id="search-result--site-specific"
          :class="{ 'search-result__site-toggle-button--selected': specific }"
          class="search-result__site-toggle-button"
          @click="setSpecific(true)">Miami News</button>
      </div>
    </div>
    <div class="gcse-searchresults"/>
  </div>
</template>

<script>
// import eventBus from '@/js/util/eventBus'

export default {
  data() {
    return {
      keyword: '',
      specific: false,
    }
  },
  created() {
  // eslint-disable-next-line
  window.__gcse.searchCallbacks = {
      web: {
        ready: this.changeFocus,
        starting: this.myWebSearchStartingCallback,
      },
      image: {
        starting: this.myWebSearchStartingCallback,
      },
    }
  },
  mounted() {
  // Read the search term from local storage
    this.keyword = window.localStorage.getItem('keyword')
    // Timeout needed to allow google search results to populate
    setTimeout(() => {
      document.getElementById('gsc-i-id1').value = this.keyword
      document.getElementById('search-result--site-specific').setAttribute('aria-pressed', 'false')
      document.getElementById('search-result--site-all').setAttribute('aria-pressed', 'true')
      document.getElementById('gsc-i-id1').setAttribute('aria-label', 'search keywords')
      document.querySelector('.gsc-search-button-v2').click()
      document.querySelector('.gsc-search-button-v2').setAttribute('aria-label', 'search submit button')
      document.querySelector('.gsc-above-wrapper-area-container').setAttribute('role', 'presentation')
      const sortBy = document.querySelector('.gsc-selected-option-container')
      const sortByItem1 = document.getElementsByClassName('gsc-option')[0]
      const sortByItem2 = document.getElementsByClassName('gsc-option')[1]
      sortBy.setAttribute('role', 'button')
      sortBy.setAttribute('tabindex', '0')
      sortBy.setAttribute('onkeypress', 'if(event.keyCode==32||event.keyCode==13){event.preventDefault() document.activeElement.click(); document.querySelector(".gsc-option-menu-item:nth-child(1)").focus()}')
      sortByItem1.setAttribute('role', 'button')
      sortByItem1.setAttribute('tabindex', '0')
      sortByItem1.setAttribute('onkeypress', 'if(event.keyCode==32||event.keyCode==13){event.preventDefault() document.activeElement.click()}')
      sortByItem2.setAttribute('onkeypress', 'if(event.keyCode==32||event.keyCode==13){event.preventDefault() document.activeElement.click()}')
      sortByItem2.setAttribute('role', 'button')
      sortByItem2.setAttribute('tabindex', '0')
      document.getElementById('gs_cb50').setAttribute('aria-hidden', true)
      window.localStorage.setItem('keyword', '')
    }, 2000)
  },
  methods: {
    changeFocus() {
      setTimeout(() => {
        document.getElementsByClassName('gs-title')[1].focus()
      }, 100)
    },
    myWebSearchStartingCallback(gname, query) {
      if (window.specific) {
        // eslint-disable-next-line
        return query + ' site:miamiredhawks.com'
      }
      return query
    },
    setSpecific(specific) {
      if (specific === this.specific) {
        return
      }
      if (specific) {
        document.getElementById('search-result--site-specific').setAttribute('aria-pressed', 'true')
        document.getElementById('search-result--site-all').setAttribute('aria-pressed', 'false')
      } else {
        document.getElementById('search-result--site-specific').setAttribute('aria-pressed', 'false')
        document.getElementById('search-result--site-all').setAttribute('aria-pressed', 'true')
      }
      this.specific = specific
      window.specific = specific
      if (document.getElementById('gsc-i-id1').value !== '') {
        document.querySelector('.gsc-search-button-v2').click()
      }
    },
  },
}
</script>
