<template>

  <div
    v-if="getPages > 1"
    class="news-pagination__container text-body">
    <nav
      class="news-pagination__indices"
      aria-label="Pagination Navigation">
      <ul class="news-pagination__list">
        <li
          v-if="pageNum != 1"
          class="news-pagination__list-item">
          <button
            class="news-pagination__link"
            @click="goToPage(pageNum - 1)"
          >
            Previous
          </button>
        </li>
        <li
          v-for="(page, index) in getPages"
          :key="index"
          class="news-pagination__list-item">
          <button
            :class="{ active: index + 1 == pageNum }"
            :aria-label="
              index + 1 == pageNum ? 'Current Page' : `Go to page ${index + 1}`
            "
            class="news-pagination__link"
            @click="goToPage(index + 1)"
          >
            {{ index + 1 }}
          </button>
        </li>
        <li
          v-if="pageNum != getPages"
          class="news-pagination__list-item">
          <button
            class="news-pagination__link"
            @click="goToPage(pageNum + 1)"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import eventBus from '../../../js/util/eventBus'

export default {
  inject: {
    newsfeed: Symbol.for('newsfeed'),
  },
  props: {
    resultsPerPage: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      pageNum: 1,
      params: Array,
      currentURL: Array,
      results: Number,
    }
  },
  computed: {
    getPages() {
      let numPages = Math.floor(
        this.newsfeed.filterList.length / this.resultsPerPage,
      )
      if (this.newsfeed.filterList.length % this.resultsPerPage > 0) {
        // eslint-disable-next-line no-plusplus
        numPages++
      }
      return numPages
    },
  },

  mounted() {
    this.newsfeed.resultsPerPage = this.resultsPerPage
    this.newsfeed.pageNum = this.pageNum
    this.goToPage(1)
    eventBus.$on('pagination', this.updatePages)
  },
  methods: {
    goToPage(pageDestination) {
      // eslint-disable-next-line no-return-assign
      this.newsfeed.filterList.forEach(post =>
        // eslint-disable-next-line no-param-reassign
        (post.show = false))
      this.newsfeed.filterList
        .slice(
          this.resultsPerPage * (pageDestination - 1),
          this.resultsPerPage * pageDestination,
        )
        .forEach((post) => {
          // eslint-disable-next-line no-param-reassign
          post.show = true
        })
      this.pageNum = pageDestination
      eventBus.$emit('updatePageNum', this.pageNum)
    },

    updatePages() {
      this.newsfeed.filterList.forEach((post) => {
        // eslint-disable-next-line no-param-reassign
        post.show = true
      })
      this.goToPage(1)

      let numPages = Math.floor(
        this.newsfeed.filterList.length / this.resultsPerPage,
      )
      if (this.newsfeed.filterList.length % this.resultsPerPage) {
        numPages = this.numPages + 1
      }
      this.getPages = numPages
    },
    /*   showPageNumber(page) {
      return 0 <= page <=  this.getPages
    },

    showNextDots() {
      return this.getPages > 5 && this.pageNum < this.getPages - this.pageNum
    },

    showPrevDots() {
      return this.pageNum > 1 && this.getPages > 5
    }, */
  },
}
</script>
<style lang="scss" scoped>
.fade-in-down-enter,
.fade-in-down-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.fade-in-down-leave-to {
  height: 0;
}

.fade-in-down-enter-active,
.fade-in-down-leave-active {
  transition: all 0.5s ease;
}
</style>
