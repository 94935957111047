<template>
  <li>
    <!-- Show a tag if there are no child elements -->
    <a
      v-if="hasChild === 'false'"
      :href="menuLink"
      tabindex="0">
      {{ menuText }}
    </a>
    <!-- Show button if there are child elements -->
    <button
      v-else
      :aria-expanded="expanded"

      @click="handleDropdownClick">

      {{ menuText }}
      <div class="sub-menu-carat-wrapper">
        <div class="sub-menu-carat" />
      </div>
    </button>
    <ul
      v-if="hasChild === 'true'"
      v-show="isOpen"
      :aria-hidden="isOpen ? 'false' : 'true'"
      class="sub-menu"
    >
      <li
        v-for="(childMenu, index) in childMenus"
        :key="index">
        <a
          tabindex="0"
          @keydown="handleMouseOutInChild($event, index, childMenu.href)"
          @click="goTo(childMenu.href)"
          :class="childMenu.lock === 'Yes' ? 'haslock' : ''"
        >{{ childMenu.text }}</a>
      </li>
      <slot />
    </ul>
  </li>
</template>

<script>
import eventBus from '../../../js/util/eventBus'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    menuText: {
      type: String,
      default: 'Menu',
    },
    menuLink: {
      type: String,
      default: '/',
    },
    hasChild: {
      type: String,
      default: 'false',
    },
    childLinks: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showMenu: false,
      isOpen: false,
    }
  },

  computed: {
    expanded() {
      const open = this.isOpen ? 'true' : 'false'
      return this.hasChild === 'true' ? open : false
    },
    childMenus() {
      if (this.childLinks === '') {
        return []
      }

      return JSON.parse(this.childLinks)
    },
  },

  created() {
    eventBus.$on('click:main:menu', this.onClickMenu)
    eventBus.$on('toggle:menu', this.onToggleMenu)
  },

  methods: {
    handleMouseOutInChild(event, index, menuLink) {
      // console.log(menuLink)
      if (event.key === 'Enter') {
        window.location.href = menuLink
      }
      if (index === this.childMenus.length - 1) {
        event.preventDefault()

        this.$el.firstChild.focus()
      }

      if (index === this.childMenus.length - 1) {
        // this.onToggleMenu()

        this.$el.children[1].children[0].firstChild.focus()
        return
      }


      if ((event.code === 'ArrowDown')) {
        event.preventDefault()
        // console.log(this.$el.children[1].children[index + 1].firstChild)
        // console.log(index)

        if (index === this.childMenus.length - 1) {
          // this.onToggleMenu()
          // console.log('this is working?')
          this.$el.children[1].children[0].firstChild.focus()
          return
        }

        this.$el.children[1].children[index + 1].firstChild.focus()
      }

      if ((event.code === 'ArrowUp')) {
        event.preventDefault()
        // console.log(this.$el.children[1].children[index + 1].firstChild)

        if (index === 0) {
          // this.onToggleMenu()

          this.$el.children[1].lastElementChild.firstChild.focus()
        } else {
          this.$el.children[1].children[index - 1].firstChild.focus()
        }
      }
    },

    onClickMenu(activeMenuData) {
      if (!activeMenuData) {
        this.isActive = true
        return
      }
      if (activeMenuData.menuId === this.id) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },

    handleDropdownClick() {
      this.isOpen = !this.isOpen
      // eventBus.$emit('click:utility:menu', this.id)
    },

    onToggleMenu() {
      this.isOpen = false
      this.showMenu = false
    },

    goTo(menuLink) {
      window.location.href = menuLink
    },

    onKeyDown(event, menuLink) {
      if (event.key === 'Enter') {
        window.location.href = menuLink
      }
    },
  },
}
</script>
