<template>
  <div class="in-news-index__inner">
    <div class="in-news-index__search-wrapper">
      <div class="in-news-index__search-container">

        <slot name="top"/>
        <div class="in-news-index__search-bar-container">
          <div class="news-search-bar">
            <div class="news-search-bar__form">
              <div class="news-search-bar__wrapper">
                <div class="news-search-bar__input-container">
                  <input
                    id="news-search-text-input"
                    ref="input"
                    type="text"
                    aria-label="Search Miami News"
                    placeholder="Search Miami News"
                    class="news-search-bar__text-input"
                    required
                    @input="inputDataChange"
                    @keydown="handleKeydown"
                  >
                  <button
                    :disabled="!clearEnabled"
                    class="news-search-bar__input-clear-button"
                    aria-label="Clear Text"
                    @click="clearText">
                    <img
                      :style="imageFilter"
                      src="//miamioh.edu/_hannonhill/_files/svgs/functional/icon-menu-white-close.svg"
                      alt="">
                  </button>
                </div>
                <button
                  type="submit"
                  class="news-search-bar__submit"
                  aria-label="Submit Search"
                  @click="filterCards">
                  <img
                    class="news-search-bar__search-icon"
                    src="//miamioh.edu/_hannonhill/_files/svgs/functional/icon-search-red.svg"
                    alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="in-news-index__search-results-wrapper">
      <div class="in-news-index__search-results-container">
        <div class="in-news-index__search-results-stats-container">
          <h2
            v-if="filterList.length > 1"
            :pageNum="pageNum"
            class="in-news-index__stats"
            @updatePageNum="updatePageNum($event)">
            Displaying
            {{ resultsPerPage * (pageNum - 1) + 1 }} -
            <span v-if="resultsPerPage * pageNum <= filterList.length ">
              {{ resultsPerPage * pageNum }}
            </span>
            <span v-else>
              {{ filterList.length }}
            </span>
            of {{ filterList.length }}
          </h2>
          <h2
            v-if="filterList.length <= 0"
            class="in-news-index__stats"> No Results Found</h2>
        </div>
        <section
          ref="cards"
          class="in-news-index__search-results-items-container">
          <slot/>
        </section>
      </div>

    </div>

  </div>
</template>

<script>
import FuzzySearch from 'fuzzy-search'
import eventBus from '../../../js/util/eventBus'

const newsfeed = Symbol.for('newsfeed')

export default {
  provide() {
    return { [newsfeed]: this }
  },

  data() {
    return {
      clearEnabled: false,
      inputData: null,
      imageFilter: 'filter: brightness(70%)',
      items: [],
      pageNum: 1,
      resultsPerPage: 7,
    }
  },

  computed: {
    filterList() {
      return this.items.filter(post => post.filtered)
    },

  },

  created() {

  },
  mounted() {
    eventBus.$on('updatePageNum', this.updatePageNum)
    this.fuzzyList = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.items.length; ++i) {
      this.fuzzyList.push({
        title: this.items[i].title,
        category: this.items[i].category,
        index: i,
      })
    }


    // threshold: 0.0 requires exact match, 1.0 will match to anything
    this.fuzzySearch = new FuzzySearch(this.fuzzyList, ['title', 'category'])
  },
  methods: {
    updatePageNum(pageNum) {
      this.pageNum = pageNum
    },

    handleKeydown(event) {
      if (event.key === 'Enter') {
        this.filterCards()
      }
    },

    inputDataChange() {
      if (this.$refs.input.value.length > 0) {
        this.imageFilter = 'filter: brightness(100%)'
        this.clearEnabled = true
      } else {
        this.imageFilter = 'filter: brightness(70%)'
        this.clearEnabled = false
      }
    },
    clearText() {
      this.$refs.input.value = ''
      this.imageFilter = 'filter: brightness(70%)'
      this.clearEnabled = false
      this.filterCards()
      this.$refs.input.focus()
    },
    filterCards() {
      if (this.$refs.input.value === '') {
        // eslint-disable-next-line no-return-assign
        // eslint-disable-next-line no-param-reassign
        this.items.forEach((item) => { item.filtered = true })
        eventBus.$emit('pagination')
        return
      }
      // only show cards that match fuzzy search
      this.results = this.fuzzySearch.search(this.$refs.input.value)
      //console.log(this.results)

      this.items.forEach((item) => {
        // check if results contain this card's title
        if (
          this.results.some(e => e.title === item.title) ||
          this.results.some(e => e.category === item.category)
        ) {
          // eslint-disable-next-line no-param-reassign
          item.filtered = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.filtered = false
        }
        eventBus.$emit('pagination')
      })
    },
  },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
