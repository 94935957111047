<script>
export default {
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        expandedarialabel: {
            type: String,
            default: '',
        },
        collapsedarialabel: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            panels: [],
            buttons: [],
            currentButton: undefined,
        }
    },
    computed: {
    },
    created() {
        this.$nextTick(() => {
            const toggleButtons = document.getElementsByClassName('bio-accordion__button')
            const accordionContents = document.getElementsByClassName('bio__content')
            for (let i = 0; i < toggleButtons.length; i += 1) {
                toggleButtons[i].onclick = () => {
                    accordionContents[i].classList.toggle('bio__content--open')
                    if (accordionContents[i].classList.contains('bio__content--open')) {
                        toggleButtons[i].setAttribute('aria-expanded', 'true')
                        // eslint-disable-next-line no-unused-expressions
                        !!this.expandedarialabel && this.expandedarialabel !== '' ? toggleButtons[i].setAttribute('aria-label', this.expandedarialabel) : ''
                    } else {
                        toggleButtons[i].setAttribute('aria-expanded', 'false')
                        // eslint-disable-next-line no-unused-expressions
                        !!this.expandedarialabel && this.expandedarialabel !== '' ? toggleButtons[i].setAttribute('aria-label', this.collapsedarialabel) : ''
                    }
                    toggleButtons[i].getElementsByClassName('bio-accordion__button-element--vertical')[0].classList.toggle('bio-accordion__button-element--vertical-hidden')
                }
            }
        })
    },
    methods: {
    },
    render(createElement) {
        return createElement(this.tag, this.$slots.default)
    },
}
</script>
