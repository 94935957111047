<template>
  <div
    id="submenu"
    ref="submenu"
    class="submenu submenu--active">
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      st: null,
      lastScrollTop: 0,
      display: true,
      heightExpanded: 0,
    }
  },
  mounted() {
    window.addEventListener('load', () => {
      this.checkForArrow()
      this.heightExpanded = document.getElementsByClassName('submenu--inner')[0].clientHeight
      this.setWrapperHeight(this.heightExpanded)
    })
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.checkForArrow)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.checkForArrow)
  },
  methods: {
    handleScroll() {
      this.st = window.pageYOffset || document.documentElement.scrollTop
      if (this.st > this.lastScrollTop + 50) {
        // downscroll code
        this.setWrapperHeight('0')
        this.$refs.submenu.classList.remove('submenu--active')
      } else if (this.st < this.lastScrollTop - 50) {
        // upscroll code
        this.setWrapperHeight(this.heightExpanded)
        this.$refs.submenu.classList.add('submenu--active')
      }
      this.lastScrollTop = this.st <= 0 ? 0 : this.st
    },
    setWrapperHeight(height) {
      const pxHeight = `${height}px`
      window.setTimeout(() => {
        this.$refs.submenu.style.height = pxHeight
        document.getElementById('submenu--wrapper').style.height = pxHeight
      }, 100)
    },
    checkForArrow() {
      if (document.getElementsByClassName('submenu-nav__list')[0].scrollWidth > window.innerWidth) {
        document.getElementsByClassName('submenu-nav')[0].classList.add('submenu-nav__arrow')
      } else {
        document.getElementsByClassName('submenu-nav')[0].classList.remove('submenu-nav__arrow')
      }

      // also check wrapper height
      this.heightExpanded = document.getElementsByClassName('submenu--inner')[0].clientHeight
      this.setWrapperHeight(this.heightExpanded)
    },
  },
}
</script>
