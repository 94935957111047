import eventBus from '../../../js/util/eventBus'

export default {
  props: {
    videoId: {
      type: Number,
      required: true,
    },
  },

  render(createElement) {
    const elementData = {
      on: {
        click: (event) => {
          event.preventDefault()
          event.stopPropagation()
          eventBus.$emit('toggle:modal', `video-${this.videoId}`)
        },
      },
      attrs: {
        // 'aria-describedby': `card-body-${this.videoId}`,
        'aria-label': `Play video`,
        id: `modal-open-video-${this.videoId}`,
      },
    }
    return createElement('button', elementData, this.$slots.default)
  },
}
