<template>
  <a
    ref="submenuLink"
    :href="link"
    @focus="onFocus()">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      heightExpanded: 0,
    }
  },
  mounted() {
    // compare pathname to url
    if (this.link === window.location.pathname) {
      this.$refs.submenuLink.classList.add('submenu-nav__link-active')
    }
    window.addEventListener('load', () => {
      this.heightExpanded = document.getElementsByClassName('submenu--inner')[0].clientHeight
    })
  },
  methods: {
    onFocus() {
      document.getElementById('submenu').style.height = `${this.heightExpanded}px`
      if (this.$el.getBoundingClientRect().left < 12) {
        document.getElementsByClassName('submenu-nav__list')[0].scrollBy(-(Math.abs(this.$el.getBoundingClientRect().left - 24)), 0)
      } else if (this.$el.parentNode.getBoundingClientRect().right > window.innerWidth - 32) {
        document.getElementsByClassName('submenu-nav__list')[0].scrollBy((this.$el.parentNode.getBoundingClientRect().right - window.innerWidth) + 32, 0)
      }
    },
  },
}
</script>
