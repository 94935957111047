<template>
  <span :aria-label="formattedDate">
    {{ formattedValue }}
  </span>
</template>

<script>
export default {
  props: {
    footerDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      const rawDate = new Date()
      const year = rawDate.getFullYear()
      const formatDate = `${year}`

      return formatDate
    },

    formattedValue() {
      return `${this.formattedDate}`
    },
  },
}
</script>
