<template>
  <nav
    v-show="showMenu"
    :aria-label="menuText"
    :id="getIdText(menuText)"
    class="header__main_menu_child"
  >
    <div
      class="header__main_menu_child_title"
      @click="showMainMenu"
    >
      <button
        aria-label="back to main menu"
        @click="showMainMenu"
        @keydown="handleCaretKeydown"
      >
        <span
          class="caret"
        />
      </button>
      <h1>
        {{ menuText }}
      </h1>
    </div>
    <ul>
      <li
        v-for="(menu, index) in menus"
        :key="index"
        @click="goToHref(menu.href)"
        @keydown="keyDownEnter($event, menu.href)"
      >
        <a
          :data-index="index"
          :href="menu.href"

          class="child-menu__button"
          @keydown="onKeyUpMenu($event, menu.href)"
          @click="goToHref(menu.href)"
        >
          {{ menu.text }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
/* eslint-disable */
import eventBus from '../../../js/util/eventBus'

export default {
  props: {
    menuId: {
      type: String,
      required: true,
    },
    previousMenuId: {
      type: String,
      default: '',
    },
    nextMenuId: {
      type: String,
      default: '',
    },
    menuText: {
      type: String,
      default: 'Menu',
    },
    links: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showMenu: false,
      isMobile: window.innerWidth < 1024,
    }
  },

  computed: {
    menus() {
      if (this.links === '') {
        return []
      }

      return JSON.parse(this.links)
    },
  },

  created() {
    eventBus.$on('click:main:menu', this.onClickMenu)
    eventBus.$on('click:child:menu', this.onClickChildMenu)
    eventBus.$on('toggle:child:menu', this.onToggleMenu)
    eventBus.$on('toggle:menu', this.onHideMenu)
  },

  methods: {
    onClickMenu(activeMenuData) {
      if (activeMenuData.menuId === this.menuId){
        if (activeMenuData.childMenu === 'first') {
          this.showMenu = true
          setTimeout(() => {
            this.$el.lastChild.firstChild.firstChild.focus()
          }, 20)
        } else if (activeMenuData.childMenu === 'last') {
          this.showMenu = true
          setTimeout(() => {
            this.$el.lastChild.lastChild.firstChild.focus()
          }, 20)
        } else if (typeof activeMenuData.childMenu === 'undefined' &&
          typeof activeMenuData.show !== 'undefined') {
          this.showMenu = activeMenuData.show
          if (this.isMobile) {
            setTimeout(() => {
              this.$el.firstChild.firstChild.focus()
            }, 20)
          }
        } else if (activeMenuData.from === 'child' &&
          !this.isMobile) {
          this.showMenu = true
        }
      } else {
        this.showMenu = false
      }
    },
    onClickChildMenu(activeMenuId) {
      if (activeMenuId === this.menuId) {
        this.$el.lastChild.firstChild.firstChild.focus()
      }
    },
    onKeyUpMenu(event, menuLink) {
      event.stopPropagation()

      //We don't want them scrolling the page while this menu is open
      if(event.key === "ArrowLeft" || event.key === "ArrowRight" || event.key === "ArrowUp" || event.key === "ArrowDown"){
        event.preventDefault()
      } 

      // If we're moving up from the first element in this submenu, go back to the main menu
      if (((event.key === 'Tab' &&
        event.shiftKey) || event.key === "ArrowUp") &&
        parseInt(event.srcElement.getAttribute('data-index'), 10) === 0) {
        event.preventDefault()
        
        //event.srcElement.blur()
        if (this.isMobile) {
          this.$el.firstChild.firstChild.focus()
        } else {
          this.$el.lastChild.lastChild.firstChild.focus()
        }
      // If we're moving down from the last element in this submenu, go back to first item in submenu (previously: go back to the main menu
      } else if (((event.key === 'Tab' &&
        !event.shiftKey) || event.key === 'ArrowDown')  &&
        parseInt(event.srcElement.getAttribute('data-index'), 10) === this.menus.length - 1) {
        event.preventDefault()
        
        //event.srcElement.blur()
        if (this.isMobile) {
          this.$el.firstChild.firstChild.focus()
        } else {
          this.$el.lastChild.firstChild.firstChild.focus()
        }
      // If we pressed the "left" key
      } else if(event.key === 'ArrowLeft') {
        event.preventDefault()
        event.srcElement.blur()
        if (this.isMobile) {
          this.$el.firstChild.firstChild.focus()
        } else {
          eventBus.$emit('click:main:menu', { menuId: this.menuId, goExpander: true, from: 'child' })
        }
      //If we pressed the "down" key
      } else if (event.key === 'ArrowDown'){
        //move down to next focusable button in sibling
        event.target.parentNode.nextElementSibling.getElementsByClassName("child-menu__button")[0].focus();
      //If we pressed the "up" key
      } else if (event.key === 'ArrowUp'){
        //move up to previous focusable button in sibling
        event.target.parentNode.previousElementSibling.getElementsByClassName("child-menu__button")[0].focus();
      } else if (event.key === 'Enter') {
        window.location.href = menuLink
      }
    },
    goToHref(menuLink) {
      window.location.href = menuLink
    },
    keyDownEnter(event, menuLink) {
      if (event.key === 'Enter') {
        window.location.href = menuLink
      }
    },
    handleCaretKeydown(event){
      event.preventDefault();
      if(event.key === 'ArrowRight'){
        event.stopPropagation();
      }
      if(event.key === 'Enter' || event.key === 'Space' || event.key === 'ArrowLeft'){
        this.showMainMenu(event);
      }
      if(event.key === 'Tab' || event.key === "ArrowUp" || event.key === "ArrowDown"){
        let buttons = this.$el.getElementsByClassName('child-menu__button');
        if(event.shiftKey || event.key === "ArrowUp"){
          buttons[buttons.length - 1].focus();
          event.stopPropagation();
        }else{
          buttons[0].focus();
          event.stopPropagation();
        }
      }
    },
    showMainMenu(event) {
      event.preventDefault()
      eventBus.$emit('toggle:child:menu', false)
      eventBus.$emit('disable:main:caret', this.menuId)
    },
    onToggleMenu(show) {
      this.showMenu = show
    },
    onHideMenu() {
      this.showMenu = false
    },
    getIdText(text) {
      return text.toLowerCase().split(' ').join('')
    },
  },
}
</script>
