<template>
  <transition
    v-if="show && filtered"
    name="fade">
    <slot/>
  </transition>
</template>
<script>
// import eventBus from '@/js/util/eventBus'


export default {
  inject: {
    newsfeed: Symbol.for('newsfeed'),
  },

  props: {
    category: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      show: true,
      filtered: true,
    }
  },
  created() {
    this.newsfeed.items.push(this)
  },

  mounted() {

  },
}

</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
